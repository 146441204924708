.cookie-consent-banner {
  display: var(--cookie-consent-display, block);
  color: rgba(27, 31, 44, .87);
  margin: 16px;
  padding: 16px;
  border-radius: 15px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  box-sizing: border-box;
  width: 530px;
  max-width: 90vw;
  background-color: #82333ECF;
  backdrop-filter: blur(1.25rem);
  -webkit-backdrop-filter: blur(1.25rem);

  p.cookie-consent-content a {
    color: white;
  }
}

.cookie-consent-banner__inner {
  margin: 0 auto;
}

.cookie-consent-banner__header {
  color: white;
  font-weight: normal;
  font-size: 20px;
}

.cookie-consent-banner__description {
  font-weight: normal;
  color: white;
  position: relative;
}

#cookie-consent-banner-full {
  display: none;
}

#cookie-consent-banner-full:not(:checked)~.text-full {
  display: none;
}

#cookie-consent-banner-full:checked~.text-cut {
  display: none;
}

label[for="cookie-consent-banner-full"] {
  text-decoration: underline;
  cursor: pointer;
}

.cookie-consent-banner__cta {
  box-sizing: border-box;
  display: inline-block;
  padding: 11px 11px;
  border-radius: 10px;
  border: 1px solid white;
  color: #FFF;
  text-decoration: none;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;

  position: absolute;
  right: 0;
  bottom: 0;
}